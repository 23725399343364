import React, { useState } from "react"
import { CSSTransition } from "react-transition-group"
import { Link } from "gatsby"
import useLockBodyScroll from "../../custom/use-lock-body-scroll"

import styled from "styled-components"
import font from "../../fonts/fonts.module.scss"
import { Button } from "../buttons"
import Newsletter from "./newsletter"

import face from "../../images/toolbar/facebook.svg"
import insta from "../../images/toolbar/instagram.svg"
import tube from "../../images/toolbar/youtube.svg"
import uber from "../../images/toolbar/uber.svg"
import logo from "../../images/logo_branco.svg"
import livro from "../../images/icon_livro_reclamacoes_preto.png"
import PremiumDent from "../../images/toolbar/CAR-FooterPremiumDent.png"
import Grupo from "../../images/toolbar/premium-grupo.png"

import { handleClick } from "../../custom/gtag-click"

const Footer = ({ footerTop, data, spaceTop, ...props }) => {
  const [modal, setModal] = useState(false)
  useLockBodyScroll(modal)

  return (
    <StyledFooter bg={data.bg}>
      <div className={spaceTop === true ? "footer space-top" : "footer"}>
        {footerTop ? null : (
          <div className="footerTop">
            <p className={font.rB + " footerTopTitle"}>
              {props.hasAppointmentButton
                ? "Marque já a sua consulta"
                : data.newsletter.subscreva}
            </p>
            {props.hasAppointmentButton ? (
              <Link
                className={`${font.rSB} footerSubscreverBtn`}
                to="/pedido-de-marcacao"
              >
                <span>Marcar consulta</span>
              </Link>
            ) : (
              <Button
                className={font.rSB + " footerSubscreverBtn"}
                onClick={() => {
                  setModal(!modal)
                }}
              >
                {data.newsletter.btnTxt}
              </Button>
            )}
          </div>
        )}
        <CSSTransition
          in={modal === true}
          timeout={350}
          classNames={"switch"}
          unmountOnExit
        >
          <Newsletter setModal={setModal} />
        </CSSTransition>

        <div className="footerBottom">
          <div className="footerContent">
            <div className="footerSegment fS1">
              <Link className="logo" to="/">
                <img
                  className="logoCarreira"
                  src={logo}
                  alt="Logo Carreira Dental Clinic"
                />
              </Link>
              <Link className="logo" to="/">
                <img className="selo" src={Grupo} alt="Selo de 20 anos" />
              </Link>
            </div>
            <div className="footerSegment fS2">
              <Link to="/contactos">
                <p className={font.rB + " fSTitle"}>{data.agueda.title}</p>
              </Link>
              <p
                className={font.rSB + " fSBottom"}
                style={{ marginBottom: "2vw" }}
              >
                <a
                  onClick={() =>
                    handleClick(`tel:+351${data.agueda.contacto[0]}`, "Águeda")
                  }
                  style={{ display: "inline-block" }}
                >
                  {data.agueda.contacto[0]}
                  <small
                    style={{
                      color: "darkgray",
                      fontSize: "15px",
                      lineHeight: "2px",
                    }}
                  >
                    ·¹
                  </small>
                  <br></br>
                </a>{" "}
                <span style={{ color: "#b7a99a" }}>&#9646;</span>{" "}
                <a
                  onClick={() =>
                    handleClick(`tel:+351${data.agueda.contacto[1]}`, "Águeda")
                  }
                  style={{ display: "inline-block" }}
                >
                  {data.agueda.contacto[1]}
                  <small style={{ color: "darkgray", fontSize: "15px" }}>
                    ·²
                  </small>
                </a>
                <p
                  className={font.rSB + " ers fSBottom"}
                  style={{ marginBottom: "0vw" }}
                >
                  ERS - 14858/2017
                </p>
              </p>

              <Link to="/contactos">
                <p className={font.rB + " fSTitle"}>{data.aveiro.title}</p>
              </Link>
              <p
                className={font.rSB + " fSBottom"}
                style={{ marginBottom: "0vw" }}
              >
                <a
                  onClick={() =>
                    handleClick(`tel:+351${data.aveiro.contacto[0]}`, "Aveiro")
                  }
                  style={{ display: "inline-block" }}
                >
                  {data.aveiro.contacto[0]}
                  <small
                    style={{
                      color: "darkgray",
                      fontSize: "15px",
                      lineHeight: "2px",
                    }}
                  >
                    ·¹
                  </small>
                  <br></br>
                </a>{" "}
                <span style={{ color: "#b7a99a" }}>&#9646;</span>{" "}
                <a
                  onClick={() =>
                    handleClick(`tel:+351${data.aveiro.contacto[1]}`, "Aveiro")
                  }
                  style={{ display: "inline-block" }}
                >
                  {data.aveiro.contacto[1]}
                  <small style={{ color: "darkgray", fontSize: "15px" }}>
                    ·²
                  </small>
                </a>
                <p
                  className={font.rSB + " ers fSBottom"}
                  style={{ marginBottom: "2vw" }}
                >
                  ERS - 11102/2015
                </p>
              </p>

              <Link to="/contactos">
                <p className={font.rB + " fSTitle"}>{data.oliveira.title}</p>
              </Link>
              <p
                className={font.rSB + " fSBottom"}
                style={{ marginBottom: "2vw" }}
              >
                <a
                  onClick={() =>
                    handleClick(
                      `tel:+351${data.oliveira.contacto[0]}`,
                      "Oliveira de Azeméis"
                    )
                  }
                  style={{ display: "inline-block" }}
                >
                  {data.oliveira.contacto[0]}
                  <small
                    style={{
                      color: "darkgray",
                      fontSize: "15px",
                      lineHeight: "2px",
                    }}
                  >
                    ·¹
                  </small>
                  <br></br>
                </a>{" "}
                <span style={{ color: "#b7a99a" }}>&#9646;</span>{" "}
                <a
                  onClick={() =>
                    handleClick(
                      `tel:+351${data.oliveira.contacto[1]}`,
                      "Oliveira de Azeméis"
                    )
                  }
                  style={{ display: "inline-block" }}
                >
                  {data.oliveira.contacto[1]}
                  <small style={{ color: "darkgray", fontSize: "15px" }}>
                    ·²
                  </small>
                </a>
                <p
                  className={font.rSB + " ers fSBottom"}
                  style={{ marginBottom: "0vw" }}
                >
                  ERS - 23663/2023
                </p>
              </p>
            </div>

            <div className="footerSegment fS2">
              <Link to="/contactos">
                <p className={font.rB + " fSTitle"}>{data.porto.title}</p>
              </Link>
              <p
                className={font.rSB + " fSBottom"}
                style={{ marginBottom: "2vw" }}
              >
                <a
                  onClick={() =>
                    handleClick(`tel:+351${data.porto.contacto[0]}`, "Porto")
                  }
                  style={{ display: "inline-block" }}
                >
                  {data.porto.contacto[0]}
                  <small
                    style={{
                      color: "darkgray",
                      fontSize: "15px",
                      lineHeight: "2px",
                    }}
                  >
                    ·¹
                  </small>
                  <br></br>
                </a>{" "}
                <span style={{ color: "#b7a99a" }}>&#9646;</span>{" "}
                <a
                  onClick={() =>
                    handleClick(`tel:+351${data.porto.contacto[1]}`, "Porto")
                  }
                  style={{ display: "inline-block" }}
                >
                  {data.porto.contacto[1]}
                  <small style={{ color: "darkgray", fontSize: "15px" }}>
                    ·²
                  </small>
                </a>
                <p
                  className={font.rSB + " ers fSBottom"}
                  style={{ marginBottom: "0vw" }}
                >
                  ERS - 19811/2020
                </p>
              </p>

              <Link to="/contactos">
                <p className={font.rB + " fSTitle"}>{data.viseu.title}</p>
              </Link>
              <p
                className={font.rSB + " fSBottom"}
                style={{ marginBottom: "0vw" }}
              >
                <a
                  onClick={() =>
                    handleClick(`tel:+351${data.viseu.contacto[0]}`, "Viseu")
                  }
                  style={{ display: "inline-block" }}
                >
                  {data.viseu.contacto[0]}
                  <small
                    style={{
                      color: "darkgray",
                      fontSize: "15px",
                      lineHeight: "2px",
                    }}
                  >
                    ·¹
                  </small>
                  <br></br>
                </a>{" "}
                <span style={{ color: "#b7a99a" }}>&#9646;</span>{" "}
                <a
                  onClick={() =>
                    handleClick(`tel:+351${data.viseu.contacto[1]}`, "Viseu")
                  }
                  style={{ display: "inline-block" }}
                >
                  {data.viseu.contacto[1]}
                  <small style={{ color: "darkgray", fontSize: "15px" }}>
                    ·²
                  </small>
                </a>
                <p
                  className={font.rSB + " ers fSBottom"}
                  style={{ marginBottom: "2vw" }}
                >
                  ERS - 20402/2021
                </p>
              </p>

              <small style={{ color: "darkgray" }}>
                ·¹«Chamada para a rede móvel nacional»
              </small>
              <small style={{ color: "darkgray" }}>
                ·²«Chamada para a rede fixa nacional»
              </small>
            </div>

            <div className="footerSegment fSLast">
              <p className={font.rB + " fSTitle"}>EMAIL:</p>
              <p
                className={font.rSB + " fSBottom"}
                style={{ marginBottom: "1vw", marginTop: "-7px" }}
              >
                <a href="mailto:clinica@carreiradentalclinic.pt">
                  <p className={font.rSB + " fSText"}>{data.email}</p>
                </a>
              </p>

              <div className="fS3Cont">
                <div className={font.rSB + " fSBottom fS3"}>
                  <span style={{ color: "#b7a99a" }}>{data.schedule[0]}</span>
                  <br />
                  <br />
                  {data.schedule[1]}{" "}
                  <span style={{ color: "#b7a99a" }}>&#9646;</span>{" "}
                  {data.schedule[2]}
                </div>
                <div className={font.rSB + " fSBottom fS3"}>
                  <span style={{ color: "#b7a99a" }}>{data.schedule[3]}</span>
                  <br />
                  <br />
                  {data.schedule[4]}
                </div>
              </div>

              <div style={{ padding: "2vw" }}></div>
              <div className={font.rSB + " fSBottom fS4"}>
                <a
                  href="https://www.facebook.com/carreiradentalclinic"
                  rel="noopener noreferrer"
                  target="_blank"
                >
                  <img src={face} alt="Logo do Facebook" />
                </a>
                <a
                  href="https://www.instagram.com/carreiradentalclinic/"
                  rel="noopener noreferrer"
                  target="_blank"
                >
                  <img src={insta} alt="Logo do Instagram" />
                </a>
                <a
                  href="https://www.youtube.com/channel/UCEtWAWV_Vvhu7jvuDQ081sQ"
                  rel="noopener noreferrer"
                  target="_blank"
                >
                  <img src={tube} alt="Logo do Youtube" />
                </a>
                <div className="uber">
                  <div className={`${font.rSB} uber-speech-bubble`}>
                    Tem consulta marcada?
                    <br />
                    Nós chamamos-lhe um Uber!
                  </div>
                  <img src={uber} alt="Logo da Uber" />
                </div>
              </div>
            </div>
            <div className="footerSignature">
              <div className={font.rSB}>
                <a
                  href="/termos_e_condicoes"
                  rel="noreferrer noopener"
                  style={{ display: "inline-block" }}
                >
                  Termos e Condições
                </a>
              </div>
              <div className={font.rSB}>
                <a
                  href="/politica_de_privacidade"
                  rel="noreferrer noopener"
                  style={{ display: "inline-block" }}
                >
                  Política de Privacidade
                </a>
              </div>
              <div className={font.rSB}>Created: Invisual.pt</div>
              <div className={font.rSB}>
                {new Date().getFullYear()} &#169; Carreira Dental Clinic
              </div>
              <div className="livro">
                <a
                  href="https://www.livroreclamacoes.pt/inicio"
                  target="_blank"
                  rel="noreferrer noopener"
                >
                  <img src={livro} alt="Logo do Livro de Reclamações" />
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </StyledFooter>
  )
}

export default Footer

const StyledFooter = styled.div`
  a {
    cursor: pointer;
  }
  .footerTop {
    text-align: center;
  }
  .footerTopTitle {
    text-transform: uppercase;
    font-size: calc(26px + 14 * (100vw - 768px) / 1152);
    @media only screen and(min-width: 1920px) {
      font-size: 40px;
    }
    letter-spacing: 0.15em;
  }
  .footerSubscreverBtn {
    width: fit-content;
    padding: 0.6em 1em;
    margin: auto;
    border: 1px solid;
    font-size: 16px;
    text-transform: uppercase;
    letter-spacing: 0.1em;
    color: #b7a99a;
    margin-top: 3em;

    -webkit-touch-callout: none;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
    user-select: none;
    cursor: pointer;

    transition: all 0.2s;
  }
  .footerSubscreverBtn:hover {
    background-color: #b7a99a;
    color: #ffffff;
    border-color: #b7a99a;
    letter-spacing: 0.25em;
  }
  .footerBottom {
    position: relative;
    background-color: #b7a99a;
    background-size: cover;
    margin-top: calc(48px + 64 * (100vw - 769px) / 1151);
    @media only screen and(min-width: 1920px) {
      margin-top: 112px;
    }
    padding: 5vw 0;
  }
  .footerContent {
    background-color: #000;
    position: relative;
    top: 20%;
    margin-left: 6.5%;
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    font-size: calc(10px + 6 * (100vw - 768px) / 1152);
    @media only screen and(min-width: 1920px) {
      font-size: 16px;
    }
    letter-spacing: 0.1em;
  }
  .footerSegment {
    padding: 5vw 0;
  }
  .fSTitle {
    color: #b7a99a;
    margin-bottom: 1em;
  }
  .fSText {
    color: #ffffff;
  }
  .fSBottom {
    color: #ffffff;

    .ers {
      margin-top: 0.5vw;
      font-size: 10px;
    }
  }
  .fS1 {
    text-align: center;
    background-color: #837c72;
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 4vw;
  }
  .fS1 {
    .logoCarreira {
      width: 70%;
    }
    .selo {
      width: 40%;
    }
  }
  .fS2 {
    display: flex;
    flex-direction: column;
    width: fit-content;
    justify-self: center;
    margin: 0 1vw;
  }
  .fSLast {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    justify-self: center;
  }
  .fS3Cont {
    display: flex;
  }
  .fS3 {
    margin-right: 1.5em;
  }
  .fS4 {
    display: flex;
    width: 100%;
    margin-top: 2vw;
  }
  .fS5 {
    display: flex;
    flex-direction: column;
    width: 100%;
    margin-top: 2vw;
  }
  .fS5 img {
    width: 50%;
  }
  .fS4 img {
    margin-right: 1em;
    max-height: 2.25em;
  }
  .uber {
    position: relative;
    margin-left: 10px;
    img {
      max-height: 2em;
    }

    &:hover .uber-speech-bubble {
      opacity: 1;
    }
  }

  // Foi-me pedido para fazer um hover no Uber em vez de linkar para o site
  // - Sérgio

  .uber-speech-bubble {
    position: absolute;
    background: #231f20;
    border-radius: 0.4em;
    text-align: center;
    width: max-content;
    padding: 15px;
    top: -250%;
    right: 20px;
    opacity: 0;
    transition: opacity 0.3s ease-out;
  }

  .uber-speech-bubble:after {
    content: "";
    position: absolute;
    bottom: 0;
    left: 80%;
    width: 0;
    height: 0;
    border: 22px solid transparent;
    border-top-color: #231f20;
    border-bottom: 0;
    border-right: 0;
    margin-left: -11px;
    margin-bottom: -22px;
  }

  .footerSignature {
    position: absolute;
    bottom: -10px;
    right: 0;
    font-size: calc(12px + 2 * (100vw - 768px) / 1152);
    @media only screen and(min-width: 1920px) {
      font-size: 14px;
    }
    letter-spacing: 0.1em;
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: flex-end;
    transform: translateY(100%);
  }
  .footerSignature div {
    margin-right: 2.5%;
  }
  .footerSignature div:last-child {
    margin-right: 5%;
  }

  .switch-enter {
    opacity: 0 !important;
  }
  .switch-enter-active {
    opacity: 1 !important;
    transition: all 350ms ease-out;
  }
  .switch-exit {
    opacity: 1 !important;
  }
  .switch-exit-active {
    opacity: 0 !important;
    transition: all 350ms ease-out;
  }

  .livro {
    max-width: 80px;
    display: flex;
    align-items: center;

    img {
      width: 100%;
      display: inline-block;
    }
  }
  .space-top {
    @media (min-width: 768px) and (max-width: 865px) {
      margin-top: calc(650px + 64 * (100vw - 769px) / 1151);
    }
    @media (min-width: 865px) and (max-width: 1020px) {
      margin-top: calc(650px + 64 * (100vw - 769px) / 1151);
    }
    @media (min-width: 1020px) and (max-width: 1201px) {
      margin-top: calc(550px + 64 * (100vw - 769px) / 1151);
    }
    @media (min-width: 1201px) and (max-width: 1400px) {
      margin-top: calc(650px + 64 * (100vw - 769px) / 1151);
    }
    @media (min-width: 1400px) and (max-width: 1710px) {
      margin-top: calc(650px + 64 * (100vw - 769px) / 1151);
    }
    @media (min-width: 1710px) and (max-width: 2400px) {
      margin-top: calc(700px + 64 * (100vw - 769px) / 1151);
    }

    @media (min-width: 2400px) and (max-width: 2600px) {
      margin-top: calc(1100px + 64 * (100vw - 769px) / 1151);
    }
    @media (min-width: 2600px) and (max-width: 3600px) {
      margin-top: calc(1200px + 64 * (100vw - 769px) / 1151);
    }
    @media (min-width: 3600px) and (max-width: 3900px) {
      margin-top: calc(1300px + (64 * (100vw - 769px)) / 1151);
    }
    @media (min-width: 3900px) and (max-width: 4300px) {
      margin-top: calc(1400px + (64 * (100vw - 769px)) / 1151);
    }
    @media (min-width: 4300px) and (max-width: 4900px) {
      margin-top: calc(1500px + (64 * (100vw - 769px)) / 1151);
    }
    @media (min-width: 4900px) {
      margin-top: calc(1600px + (64 * (100vw - 769px)) / 1151);
    }
  }
`
